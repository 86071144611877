/** @jsx jsx */
import { Box, jsx } from "theme-ui"
import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import {
  findBySlug,
  getContentfulPageSectionListData,
  getContentfulSectionData,
} from "../helpers"
import get from "lodash/get"
import { Button, Flex, Grid } from "@theme-ui/components"

import Column from "../components/column"
import Form from "../components/contact-form"
import Container from "../components/container"
import HeadquartersModule from "../components/headquarters-module"
import Page from "../components/page"
import PageLink from "../components/page-link"
import Row from "../components/row"
import Text from "../components/text"
import theme from "../gatsby-plugin-theme-ui/index"
import NewsletterForm from "../components/newsletter-form"
import { paramsCheck } from "../functions/functions"
import blueprint from "../images/blueprint-icon.svg"
import lightbulbIcon from "../images/lightbulb-icon.svg"


const ContactUs = ({ data }) => {
  const GENERALINFORMATION = 'general-information'
  const PROJECTENGAGEMENT = 'project-engagement'
  paramsCheck()
  const [showContact, setShowContact] = React.useState(false)
  const [showformselection, setShowFormSelection] = React.useState(true)
  // if user select any one project inquiry
  const [showForm, setshowForm] = useState("")
  const [mainFormData, setMainFormData] = useState("")
  const [screenWidth, setScreenWidth] = useState("")
  const [leadsourceDetail, setLeadsourceDetail] = useState("")

  useEffect(() => {

    if (mainFormData) {
      let data = [...mainFormData]
      if (mainFormData?.detailField) {
        data.defaultFields = {
          ...data.defaultFields,
          [data.detailField]: `Contact Form`,
        }
      }

      if (data.conversionDetail) data.conversionDetail = "4T7jCOXcl5gBEJeLhv0C"
      setMainFormData(data)
    }
  }, [data])

  useEffect(() => {
    if (checkIfWindow) {
      setWindowWidth()
      let params = new URLSearchParams(window.location.search);
       let urlCheck = params.get("leadsourcedetail");
       if(urlCheck && urlCheck !==""){
        setLeadsourceDetail(urlCheck)
        if(urlCheck ==="Outbound Contact Form"){
          let element = document.getElementsByTagName("header")[0].getElementsByTagName("a")[0];
          element.setAttribute("onclick","return false;")
          element.setAttribute("href","#")
       
        }
        }
    }
  }, [])

  const renderContactForm = () => {
    // const contactFormBladePatternData = findBySlug({
    //   list: data.contentfulPage.bladePatternList,
    //   slug: "contact-us-form-module",
    // })
    return (
      <Container
        customStyle={{
          pb: theme => [
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
          ],
          pl: [10, 10, 10, 10],
        }}
      >
        {mainFormData && (
          <Form
            customStyle={{ px: 0, pl: [0, null, null] }}
            formData={mainFormData}
            moveToTopOnSubmit={true}
            hideFormSelection={hideFormSelection}
          ></Form>
        )}
      </Container>
    )
  }
  const renderCTAs = () => {
    const contactCtasBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "contact-us-ctas",
    }).contentCards
    return (
      <div
        id="custom-efficient-world-class-design"
        sx={{
          backgroundColor: theme => theme.colors.backgrounds.primary,
          pt: theme => [
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
            theme.spacing.vertical.md,
          ],
          pb: theme => [theme.spacing.vertical.sm],
          mb: theme => [theme.spacing.vertical.md],
        }}
      >
        <Container customStyle={{ pl: [20, 20, 10, 10] }}>
          {contactCtasBladePatternData && (
            <Grid columns={[1, 3, 3, 5]}>
              {/* {contactCtasBladePatternData[0] && (
                <Box>
                  <Text type="h3" customStyle={{ mb: theme => [0, 0, 0] }}>
                    {contactCtasBladePatternData[0].title}
                  </Text>
                  <div sx={{ width: "90%" }}>
                    <div sx={{ marginBottom: "10px", marginTop: "10px" }}>
                      <PageLink
                        link={
                          get(
                            contactCtasBladePatternData[0],
                            "ctaDestination.ctaDestination"
                          ) ||
                          get(
                            contactCtasBladePatternData[0],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          ) ||
                          "/"
                        }
                        text={`${contactCtasBladePatternData[0].ctaTitle} >`}
                        linkType={
                          get(
                            contactCtasBladePatternData[1],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          )
                            ? "external"
                            : "internal"
                        }
                        customStyle={{
                          mb: ["30px", "30px", 0],
                          mt: 3,
                        }}
                      ></PageLink>
                    </div>
                  </div>
                </Box>
              )} */}
              {contactCtasBladePatternData[1] && (
                <Box>
                  <Text type="h3" customStyle={{ mb: theme => [0, 0, 0] }}>
                    {contactCtasBladePatternData[1].title}
                  </Text>
                  <div sx={{ width: "90%" }}>
                    <div sx={{ marginBottom: "10px", marginTop: "10px" }}>
                      <PageLink
                        customStyle={{ color: "primary", my: 3 }}
                        link={
                          get(
                            contactCtasBladePatternData[1],
                            "ctaDestination.ctaDestination"
                          ) ||
                          get(
                            contactCtasBladePatternData[1],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          ) ||
                          "/"
                        }
                        text={`${contactCtasBladePatternData[1].ctaTitle} >`}
                        linkType={
                          get(
                            contactCtasBladePatternData[1],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          )
                            ? "external"
                            : "internal"
                        }
                      />
                    </div>
                  </div>
                </Box>
              )}
              <Box>
                {contactCtasBladePatternData[2] && (
                  <React.Fragment>
                    <Text type="h3">
                      {contactCtasBladePatternData[2].title}
                    </Text>
                    <div sx={{ mb: ["10px", 0, 0], marginTop: "10px" }}>
                      <PageLink
                        customStyle={{ color: "primary", my: 3 }}
                        link={
                          get(
                            contactCtasBladePatternData[2],
                            "ctaDestination.ctaDestination"
                          ) ||
                          get(
                            contactCtasBladePatternData[2],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          ) ||
                          "/"
                        }
                        text={`${contactCtasBladePatternData[2].ctaTitle} >`}
                        linkType={
                          get(
                            contactCtasBladePatternData[2],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          )
                            ? "external"
                            : "internal"
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
              </Box>
              <Box>
                {contactCtasBladePatternData[3] && (
                  <React.Fragment>
                    <Text type="h3">
                      {contactCtasBladePatternData[3].title}
                    </Text>
                    <div sx={{ mb: ["10px", 0, 0], marginTop: "10px" }}>
                      <PageLink
                        customStyle={{ color: "primary", my: 3 }}
                        link={
                          get(
                            contactCtasBladePatternData[3],
                            "ctaDestination.ctaDestination"
                          ) ||
                          get(
                            contactCtasBladePatternData[3],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          ) ||
                          "/"
                        }
                        text={`${contactCtasBladePatternData[3].ctaTitle} >`}
                        type={
                          get(
                            contactCtasBladePatternData[3],
                            "ctaDestinationExternal.ctaDestinationExternal"
                          )
                            ? "external"
                            : "internal"
                        }
                      />
                    </div>
                  </React.Fragment>
                )}
              </Box>
              <Box>
                <Text type="h3">Newsletter Signup</Text>
                <Button
                  sx={{
                    ...theme.forms.buttonChevron,
                    "&::after": {
                      content: "'>'",
                      ml: 1,
                      position: "relative",
                      bottom: "1px",
                    },
                    fontSize: 15,
                    pr: "75px",
                    py: "0px",
                    pl: 0,
                    mt: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: 120,
                    color: "primary",
                    border: "none",
                    background: "transparent",
                    whiteSpace: "nowrap",
                    ":hover": {
                      border: `none`,
                      color: "secondary",
                    },
                  }}
                  onClick={() => setShowContact(true)}
                >
                  Signup
                </Button>
              </Box>
            </Grid>
          )}
        </Container>
      </div>
    )
  }
  const renderHeadquartersModule = () => {
    const bladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "contact-us-headquarters-and-factory",
    })
    if (!bladePatternData) return

    return (
      <HeadquartersModule
        bladePatternData={bladePatternData}
        size={[9, 3]}
        containerPadding={[20, 20, 10, 10]}
        justify={"normal"}
        imgheight={"85%"}
      />
    )
  }
  const getFormData = value => {
    let formData
    if (value === GENERALINFORMATION) {
      // formData = getContentfulSectionData({
      //   sectionList: data.allContentfulSection.edges,
      //   sectionSlug: "contact-us-general-information",
      // })
      let sectionData = get(data.general, "content.internal.content");
      formData = JSON.parse(sectionData)
    } 

    formData.defaultFields = {
      ...formData.defaultFields,
      [formData.detailField]: `Contact Form`
    }

    //put condition here for google ads apis

    if (formData.conversionDetail) {
      if(value==GENERALINFORMATION){
        formData.conversionDetail = "98bUCKuTvOMCEJeLhv0C"
      }
    }
    if(formData.SalesforceFields && leadsourceDetail !==""){
      formData.SalesforceFields["00N8c00000bo3ab"] =  leadsourceDetail
    }
    let formfields = {
      ...formData,
      selectedForm: value,
    }
    setMainFormData(formfields)
    if (value === GENERALINFORMATION) {
      setshowForm(GENERALINFORMATION)
    } 
  }
  const renderFormSelection = () => {
    const flexStyle = {
      top: { flexDirection: ["column"] },
      bottom: { flexDirection: ["column", "column", "column-reverse"] },
    }
    const rowStyle = {
      top: { mt: theme => [3, 3,3, theme.spacing.vertical.lg] },
      bottom: { mt: [0, 0, 8] },
    }
    const textStyle = {
      color: "#31aabb",
      mb: "10px",
      mt: "10px",
    }
    return (
      <Container>
        {showformselection && (
          <Row customStyle={{ ...rowStyle["top"], pt: [30, 30, null, null] }}>
            <Column size={[12, 12, 12, 4]} customStyle={{ pl: [10, 10, 0, 0] }}>
              <Text type="h2" customStyle={{ fontSize: [40, 40, 56] }}>
                Project Inquiries
              </Text>
              <Text customStyle={{ mt: 3, fontSize:17 }}>
              {/* Please take a few minutes to complete our questionnaire so we can provide a prompt and helpful response. There is a maximum of eight questions. */}
              Please take a few minutes to complete our questionnaire.
              </Text>
            </Column>
          </Row>
        )}
        <Flex sx={flexStyle["bottom"]}>
          <Row
            customStyle={{
              zIndex: 2,
              mb:showformselection? theme => [theme.spacing.vertical.md]: 0,
              pl: 0,
            }}
          >
            
            <Column
              customStyle={{
                
                opacity: () =>
                  showForm.length !== 0 && showForm !== GENERALINFORMATION
                    ? 0.4
                    : 1,
                pl: [10, 10, 10, 0],
              }}
              size={[12, 12, 4]}
            >
              {showformselection && (
                <>
                  {/* </Flex> */}
                  
                  <div
                    sx={{
                      mt: theme => [theme.spacing.vertical.sm],
                      mb: theme => [theme.spacing.vertical.sm],
                    }}
                  >
                    {/* <Button
                      sx={{
                        ...theme.forms.buttonChevron,
                        "&::after": {
                          content: "'>'",
                          ml: 10,
                          position: "relative",
                          bottom: "1px",
                        },
                        fontSize: 14,
                        pr: "75px",
                        py: "5px",
                        pl: 19,
                        display: "flex",
                        justifyContent: "space-between",
                        width: 150,
                        color: "#ffffff",
                        border: theme => `2px solid ${theme.colors.primary}`,
                        background: "#31aabb",
                        whiteSpace: "nowrap",
                        ":hover": {
                          border: theme =>
                            `2px solid ${theme.colors.secondary}`,
                          color: "secondary",
                        },
                      }}
                      // onClick={() => {
                      //   getFormData(GENERALINFORMATION)
                      // }}
                    >
                      Begin
                    </Button> */}
                    <Link
                      type="external"
                      sx={{
                        ...theme.forms.buttonChevron,
                        "&::after": {
                          content: "'Begin >'",
                          ml: 10,
                          position: "relative",
                          bottom: "1px",
                        },
                        fontSize: 14,
                        pr: "75px",
                        py: "5px",
                        pl: 19,
                        display: "flex",
                        justifyContent: "space-between",
                        width: 150,
                        color: "#ffffff",
                        border: theme => `2px solid ${theme.colors.primary}`,
                        background: "#31aabb",
                        whiteSpace: "nowrap",
                        textDecoration: "none",
                        ":hover": {
                          border: theme =>
                            `2px solid ${theme.colors.secondary}`,
                          color: "secondary",
                        },
                      }}
                      target="_blank"
                      to="https://form.jotform.com/250694671535060"
                    >
                    </Link>
                  </div>
                </> 
              )}
              {showForm === GENERALINFORMATION &&
              checkIfWindow() &&
              screenWidth < 767
                ? renderContactForm()
                : null}
            </Column>
          </Row>
        </Flex>
      </Container>
    )
  }

  const hideFormSelection = () => {
    setShowFormSelection(false)
  }
  const setWindowWidth = () => {
    setScreenWidth(window.screen.availWidth)
  }

  const checkIfWindow = () => {
    if (typeof window !== "undefined") {
      window.addEventListener("orientationchange", setWindowWidth)
      return typeof window !== "undefined"
    }
    return typeof window !== "undefined"
  }

  return (
    <Page data={data.contentfulPage}>
      {showContact && <NewsletterForm onClose={() => setShowContact(false)} />}
      {renderFormSelection()}
      {showForm && checkIfWindow() && screenWidth >= 767
        ? renderContactForm()
        : null}
        {leadsourceDetail !=="Outbound Contact Form" &&
        <>
          {renderCTAs()}
          {renderHeadquartersModule()}
        </>
      }
      
    </Page>
  )
}

export default ContactUs

export const query = graphql`
  query contactUsPageQuery {
    contentfulPage(slug: { eq: "contact-us" }) {
      ...PageData
      ...BladePatternData
    }
    general: contentfulSection(slug: {eq: "contact-us-project-inquiries"}) {
      slug
      content {
        internal {
          content
        }
      }
    }
  }
`
